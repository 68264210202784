import _ from 'lodash';
import dynamic from 'next/dynamic';

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import SectionHPopular from 'src/components/sections/section-h-popular';
import SectionV from 'src/components/sections/section-v';
import { EMBED_ELECTION_ON_WEB, IFRAME_ELECTION } from 'src/constants';
import { GET } from 'src/services';
import { KEY_ADS_PAGE, KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { convertDatasets } from 'src/utils/datasets';
import { isRender } from 'src/utils/device';
import { convertObjPath } from 'src/utils/helper';
import styled from 'styled-components';
import SectionFullH from '../section-full-h';

const CarouselFull = dynamic(() => import('src/components/carousels/carousel-full'), { ssr: true });
const DynamicAds = dynamic(import('src/utils/ads/dynamic-ads'), { ssr: true });
const SectionHighlight = dynamic(import('src/components/sections/section-highlight'), { ssr: false });

const SectionOne = ({ data, dataLatest, dataPopular, sectionEventPopular, controlAds, ads, showSectionElection, sectionEventOne, usElectionLink }) => {
  const router = useRouter();
  if (_.isEmpty(data)) return null;

  const [resData, setResData] = useState([]);
  const [resDataSectionEvnetOne, setResDataSectionEvnetOne] = useState([]);

  const resEventData = {
    item1: convertDatasets(sectionEventPopular?.dataSet, 'open-close', true),
    item2: convertDatasets(sectionEventPopular?.dataSet, 'ข้อมูลที่1', true),
    item3: convertDatasets(sectionEventPopular?.dataSet, 'รูปที่1', true),
    item4: convertDatasets(sectionEventPopular?.dataSet, 'รูปโลโก้1', true)
  };

  const sectionEventOnes = {
    item1: convertDatasets(sectionEventOne?.dataSet, 'logo-img-1', true),
    item2: convertDatasets(sectionEventOne?.dataSet, 'background-img-1', true),
    item3: convertDatasets(sectionEventOne?.dataSet, 'logo-right-img-1', true),
    item4: convertDatasets(sectionEventOne?.dataSet, 'title-1', true),
    item5: convertDatasets(sectionEventOne?.dataSet, 'title-2', true),
    item6: convertDatasets(sectionEventOne?.dataSet, 'data-1', true),
    item7: convertDatasets(sectionEventOne?.dataSet, 'head-title-1', true)
  };

  useEffect(() => {
    const res = async () => {
      if (!_.isEmpty(sectionEventOne) && sectionEventOnes?.item6 !== null) {
        const result = await GET(`/api/search/tag${convertObjPath({ name: sectionEventOnes?.item6 })}&limit=2`, false, false);
        const mapResult = {
          data: result,
          title: sectionEventOnes?.item7,
          link: `/tags/${sectionEventOnes?.item6}`
        };
        setResDataSectionEvnetOne(mapResult);
      }
    };

    res();
  }, [!_.isEmpty(sectionEventOne)]);

  useEffect(() => {
    const res = async () => {
      if (!_.isEmpty(resEventData) && resEventData?.item1 === 'open') {
        const result = await GET(`/api/search/tag${convertObjPath({ name: resEventData?.item2 })}&limit=3`, false, false);
        const mapResult = {
          data: result,
          title: resEventData?.item2,
          link: `/tags/${resEventData?.item2}`
        };
        setResData(mapResult);
      }
    };

    res();
  }, []);

  return (
    <>
      {controlAds === 'top' && <>{!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}</>}
      {!_.isEmpty(showSectionElection) && _.find(showSectionElection, { key: 'isShow' })?.value === 'true' && (
        <IframeWrapper id='section-iframe-election' className='section-iframe-election' src={EMBED_ELECTION_ON_WEB || IFRAME_ELECTION} width='100%' />
      )}
      <div className='default-carousel-full'>
        <CarouselFull
          data={data?.newHighlight}
          //! DATALAYER
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            section: 'section-1',
            position: 'ใต้:header',
            data: {
              heading: 'highlight-1'
            }
          }}
        />
      </div>
      {controlAds !== 'top' && <>{!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}</>}
      <SectionHighlight
        data={data?.newHighlight2}
        usElectionLink={usElectionLink}
        sectionEventOne={resDataSectionEvnetOne}
        sectionEventOneItem={sectionEventOnes}
        //! DATALAYER
        eventDataLayer={{
          type: DATALAYER_TYPE.TRACK_POSITION,
          router: router?.pathname,
          section: 'section-2',
          position: 'ใต้:โฆษณาตำแหน่ง billboard',
          data: {
            heading: 'highlight-2'
          }
        }}
      />

      {/* ---------------------------- ADS PPN : INARTICLE_1 --------------------------- */}
      {isRender('mobile') && <DynamicAds page={KEY_ADS_PAGE.HOMEPAGE} position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />}

      <section id='section-3' className='container default-section'>
        <div className='row'>
          <div className='col-12 col-lg-8'>
            {!_.isEmpty(dataLatest?.data) && (
              <SectionV
                data={dataLatest?.data}
                count={6}
                heading='ข่าวล่าสุด'
                badge={false}
                path='/category/latest-news'
                sectionCol='col-lg-4 col-md-6 col-6'
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'section-3',
                  position: 'ใต้:section-2',
                  data: {
                    block: '1',
                    heading: 'ข่าวล่าสุด'
                  }
                }}
              />
            )}
          </div>
          {!_.isEmpty(resData) ? (
            <div className='col-12 col-lg-4 bkk-wrapper-section-event'>
              <SectionEventBKK className='bkk-section-event' backgroundEv={resEventData?.item3}>
                <div className='bkk-section-event-img'>
                  <img src={`${resEventData?.item4}`} alt={`${resEventData?.item2}`} />
                </div>
                <SectionFullH
                  data={resData}
                  count={3}
                  path={resData.link}
                  heading={resData.title}
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    section: 'section-3',
                    position: 'ใต้:section-2',
                    data: {
                      block: '2',
                      heading: resData.title
                    }
                  }}
                />
              </SectionEventBKK>
            </div>
          ) : (
            <div className='col-12 col-lg-4 section-popular'>
              {!_.isEmpty(dataPopular?.data) && (
                <SectionHPopular
                  data={dataPopular.data}
                  count={5}
                  heading='ข่าวยอดนิยม'
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    section: 'section-3',
                    position: 'ใต้:section-2',
                    data: {
                      block: '2',
                      heading: 'ข่าวยอดนิยม'
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SectionOne;
const IframeWrapper = styled.iframe`
  height: 900px;
  border: unset;
  margin-top: 20px;
`;
const SectionEventBKK = styled.div`
  &.bkk-section-event {
    background: ${props => `url(${props?.backgroundEv})`};
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      margin-bottom: 0;
      border-radius: 0;
    }
    .bkk-section-event-img {
      display: flex;
      justify-content: center;
      img {
        margin-top: -15px;
        width: 100%;
        max-width: 350px;
        height: 100%;
      }
    }

    .block-title {
      border-bottom: unset;
      justify-content: end;
      margin-bottom: 10px;
      h2 {
        display: none;
      }
      a {
        color: #fff;
        &:hover {
          svg {
            color: #0600E7;
          }
        }
        svg {
          color: #fff;
        }
      }
    }
    .block-wrapper {
      background-color: #5a5a5aa6;
      border-radius: 5px;
      margin-bottom: 0;
      padding: 10px;
      margin-top: -15px;
      .block-list {
        li {
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
        .card-h-content {
          h3 {
            color: #fff;
          }
        }
        .card-h-content-meta {
          svg,
          span {
            color: #ffffffc1;
          }
        }
      }
    }
  }
`;
